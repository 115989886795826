@import url("https://fonts.googleapis.com/css2?family=Schibsted+Grotesk:ital,wght@0,400..900;1,400..900&display=swap");

body {
  background-color: #f5f7fa;
  top: 0;
  left: 0;
  margin: 0px 0px !important;
  text-align: left;
  scrollbar-width: 0px;
}

.onest {
  font-family: "onest", sans-serif;
}
.grotesk {
  font-family: "Schibsted Grotesk", sans-serif;
}

.inter {
  font-family: "inter", sans-serif;
}

.navbar {
  height: 15vh;
}

li {
  text-decoration: none;
  list-style: none;
  padding: 15px;
}

.hero-product {
  color: #2f269d;
}

.pages {
  margin: 5px;
  width: 65vw;
}

.demo {
  width: 159px;
  height: 54px;
}

.new {
  width: 40%;
  height: 12%;
}

.btn-design {
  background-image: linear-gradient(to top right, #016cd8, #0080ff);
  border-radius: 27px;
  padding: 10px;
  border: none;
  color: white;
  font-size: 14px;
}

.tab-container {
  height: 64px;
  border-radius: 61px;
}

.tabs {
  margin-right: 15px;
  padding: 8px 24px;
}

.active-tab {
  width: 25%;
  background-color: #00264c;
  border-radius: 27px;
  color: white;
  height: 48px;
}

img {
  height: 250px;
  width: 250px;
}

.logo-img {
  height: 32px;
  width: 131px;
}

.left {
  max-width: 60%;
  height: 413px;
  position: relative;
  top: 50px;
  align-items: baseline;
  padding-left: 2rem;
}

.whyleft {
  max-width: 60%;
  height: 413px;
  padding-left: 2rem;
}

.whyleft2 {
  padding-left: 5rem;
}
.banner-left {
  width: 37%;
  height: 413px;
  top: 60px;
  padding-left: 3rem;
}

.dashboard {
  position: inherit;
  height: 515px;
  width: 918px;
}

.tab-content {
  /* font-size: 20px; */
  margin: 2rem;
  color: #52606d;
  width: 60%;
}

.content {
  max-width: 31rem;
  font-family: "Onest", sans-serif;
  margin: 2rem 0 1rem 0;
}

.banner-feature {
  background: linear-gradient(96.3deg, #e8f4ff 0%, #fef3ed 100.39%);
  border-radius: 48px 8px;
  height: 396px;
}

h2 {
  color: #2f269d;
}

.righted {
  background: linear-gradient(
    270.44deg,
    #ffffff 0.76%,
    rgba(253, 240, 247, 0.87451) 98.01%
  );
}
.lefted {
  background: linear-gradient(111.46deg, #ffffff 0%, #f0f0fe 98.59%);
}

.feature {
  margin: 5rem;
}

.heading {
  margin-top: 2rem;
  font-size: 64px;
}

body {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.accordion {
  max-width: 900px;
  margin: auto;
}

.accordion-item {
  border-radius: 12px;
  overflow: hidden;
}

.accordion-checkbox {
  display: none;
}

.accordion-checkbox:checked + .accordion-header .icon {
  transform: rotate(45deg);
  transform: 0.5s ease-in-out;
  content: "-";
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  background-color: #fff;
}

.accordion-checkbox:checked + .accordion-header {
  padding: 1rem;
  max-height: 1000px; /* large value to ensure it opens fully */
}

.input-email {
  border: 1px solid #323549;
  border-radius: 33.5px 0px 0px 33.5px;
}

.email > button {
  background: #fafafa;
  border-radius: 0px 33.5px 33.5px 0px;
}

.accordion-content p {
  padding: 15px;
}

.integration {
  background: linear-gradient(113.83deg, #fdf0f7 0%, #f0f0fe 100%);
}

.why {
  text-align: center;
  font-size: 64px;
}

.faq {
  font-size: 48px;
}

.us {
  color: #2f269d;
}

.why-left {
  width: 90vw;
  height: 493px;
  border-radius: 40px;
  margin: 2rem auto;
}

.whyUs {
  margin: 0px auto;
}

.right {
  display: flex;
  width: 548px;
  height: 493px;
}

.why-img {
  justify-content: center;
  margin: auto;
}

.how-heading {
  font-size: 28px;
  color: #0177ed;
}

.how-content {
  font-size: 18px;
  color: #52606d;
}

.why-content {
  font-size: 20px;
  margin: 10px 0 20px 30px;
}

.how-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.data {
  max-width: 80%;
  margin-left: 3rem;
}

.why-heading {
  color: #2f269d;
  font-size: 48px;
}

.why-logo {
  height: 45px;
  width: 45px;
}

.left > .why-img {
  border-radius: 24px 0 0 24px;
}
.right > .why-img {
  border-radius: 0 24px 24px 0;
}

.howWorks > .left {
  height: 350px;
  top: 0px;
}

.testinomial > .left {
  font-size: 48px;
}

.card {
  height: 464px;
  width: 531px;
  border-radius: 11px 0 48px 0;
  border: 2px solid #a2c9f0;
}

.text {
  font-size: 24px;
  font-family: "onest", sans-serif;
  color: #52606d;
  padding: 25px;
  width: 85%;
}

.person-img {
  height: 64px;
  width: 64px;
}
.name {
  font-size: 24px;
  font-family: "onest", sans-serif;
  color: #2d1f6b;
}

.signup {
  background-image: linear-gradient(to bottom, #13213b, #11203d);
}

input {
  width: 302px;
  height: 40px;
  border-radius: 8px;
}

.p {
  width: 100%;
  margin-top: 4rem;
}
.footer-logo {
  width: 32px;
  height: 32px;
}

.f-text {
  margin-left: 0.6rem;
}
.p-r {
  margin: 1rem 0;
  color: #9ea3ad;
  width: 70%;
  padding: 10px;
}

.p > .p-r {
  margin: 0;
}

.why-grid {
  margin: 1rem auto;
}

.trusted-img {
  height: 28px;
  width: 160px;
}

.carousel-container {
  overflow: hidden;
}

.carousel-track {
  animation: scroll 30s linear infinite;
  white-space: nowrap;
}

.carousel-item {
  flex: 0 0 auto;
  margin-right: 100px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 35s linear infinite;
}

.carousel-track {
  width: 200%;
}

.carousel:hover .animate-scroll {
  animation-play-state: paused;
}

.form {
  width: 457px;
  height: 740px;
  top: 202px;
  left: 864px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  font-family: "onest", sans-serif;
  box-shadow: 0px 8px 24px 0px #d1d1d173;
  border-radius: 22px;
}

.demoPage {
  height: 740px;
}

.ai-right {
  width: 456px;
  height: 473px;
}

.why-left-feature {
  height: 473px;
  width: 90vw;
  border-radius: 40px;
  margin: 2rem auto;
}

.even-bg {
  background: linear-gradient(111.46deg, #ffffff 0%, #f0f0fe 98.59%);
}
.odd-bg {
  background: linear-gradient(
    270.53deg,
    #ffffff 0.92%,
    rgba(253, 240, 247, 0.87451) 99.08%
  );
}

.dropdown-menu {
  width: 350px;
}

.testimonial-card {
  transition: opacity 0.3s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

h1 {
  font-size: large;
  font-weight: bold;
  margin: 2rem;
}

p {
  margin: 0rem 2rem;
}

.about-banner {
  left: 0px;
  background: linear-gradient(96.3deg, #fef3ed 0%, #e8f4ff 100.39%);
  border-radius: 0px 0px 24px 24px;
}

.integrations {
  overflow: hidden;
}

.int-banner {
  background: linear-gradient(96.3deg, #fef3ed 0%, #e8f4ff 100.39%);
}
.bg-1 {
  background: linear-gradient(96.3deg, #fef3ed 0%, #e8f4ff 100.39%);
}

.onboard {
  background: linear-gradient(96.3deg, #fef3ed 0%, #e8f4ff 100.39%);
}

.onboard-button {
  background-color: #4d85d5;
  border-radius: 15px;
}

.loader-box {
  border: 4px solid transparent;
}
.loader-box:before {
  content: "";
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
}
.loader {
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
